$(function(){
  // console.log("Working");


  // $("#contact-form").on("click", function(e){
  //   e.preventDefault();
  // })


  $(".trigger").on("click", function(e){
    $("header").toggleClass("isOpen");
  });

  $(".beforeAfterContainer").twentytwenty();



  $(".phonenumber").mask("1 (999) 999-9999",{placeholder:"1 (XXX) XXX-XXXX"});



  // console.log(projectSlides);





  var pswpElement = $(".pswp")[0];


    $(".photos-list .image").on("click", function(e){
      // console.log("Clicked");
      e.preventDefault();

      var theIndex = $( ".photos-list .image" ).index( this );
      // console.log(theIndex);
      var el = $(this);
      // console.log(el);
      // console.log(companyItems);

      var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, projectSlides,
        {
          index: theIndex,
          bgOpacity:1,
          captionEl: false,
          fullscreenEl: false,
          history: false,
          modal: true,
          closeOnScroll: true,
          zoomEl: true,
          shareEl: false,
          getThumbBoundsFn: function(index){
            var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
            return {x:el.position.left, y:el.offset().top + pageYScroll, w:el.width()};
          }
          // counterEl: false
        }
      );

      gallery.init();

      // console.log(gallery);

    });









});
